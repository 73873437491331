import { Skeleton } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { useOrdersB2B } from 'commercetools/hooks/use-orders-b2b'
import { useProductSearch } from 'commercetools/hooks/use-product-search'
import { ProductsTable } from 'components/modules/Product/ProductsTable/ProductsTable'
import { productListTypes } from 'gtm/productListTypes'
import { trackViewProductsList } from 'gtm/trackViewProductsList'

type Props = { isStartPage?: boolean; isOpen?: boolean }

export function RecentlyBoughtItems({ isStartPage = false, isOpen = false }: Props) {
  const { t, lang } = useTranslation('common')

  const businessUnit = useBusinessUnit()

  const limit = 15
  const priceTitle = isStartPage ? t('common:net-price') : t('todays-net-price')
  const variant = isStartPage ? 'order-template-sticky' : 'responsive'
  const [isTracked, setIsTracked] = useState(false)

  const recentlyBoughtItemsSKUs: string[] = []

  const { data: orders, isLoading: isLoadingOrders } = useOrdersB2B({ page: 1, limit: limit, sort: 'createdAt desc' })

  orders?.results?.forEach((order) => {
    order.lineItems.map((lineItem) => {
      if (recentlyBoughtItemsSKUs.length === limit) {
        return
      }
      if (!recentlyBoughtItemsSKUs.find((sku) => sku === lineItem.variant?.sku) && lineItem?.variant?.sku) {
        recentlyBoughtItemsSKUs.push(lineItem.variant.sku)
      }
    })
  })

  const {
    data: productsResponse,
    isFetched,
    isLoading,
  } = useProductSearch({
    limit: limit,
    searchQuery: '',
    filter: [
      `variants.sku:"${recentlyBoughtItemsSKUs.join('","')}"`,
      `variants.attributes.erpLegalEntity.key:${businessUnit.erpLegalEntity}`,
    ],
  })

  const sortedProducts = productsResponse?.results?.sort(
    (a, b) =>
      recentlyBoughtItemsSKUs.indexOf(a.masterVariant.sku || '') -
      recentlyBoughtItemsSKUs.indexOf(b.masterVariant.sku || ''),
  )

  const products = isStartPage ? sortedProducts?.slice(0, 5) : sortedProducts

  useEffect(() => {
    if (isFetched && products && products.length && !isTracked && (isOpen || isStartPage)) {
      trackViewProductsList(products, lang, productListTypes.recentlyBoughtItems)
      setIsTracked(true)
    }
  }, [products, isFetched, isStartPage, isOpen, isTracked, lang])

  return (
    <>
      {isLoading || isLoadingOrders ? (
        <Skeleton width="full" height="96" />
      ) : (
        <ProductsTable variant={variant} priceTitle={priceTitle} products={products} />
      )}
    </>
  )
}
