import { ErrorObject, OrderPagedQueryResponse } from '@commercetools/platform-sdk'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { fetchBuOrders } from 'commercetools/api/order'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { OrderQueryParams } from 'commercetools/types'

export function useOrdersB2B(
  params: OrderQueryParams,
  config?: UseQueryOptions<OrderPagedQueryResponse, ErrorObject>,
): UseQueryResult<OrderPagedQueryResponse, ErrorObject> {
  const { search, from, to, shippingAddress, page = 1, limit = 15, sort = 'createdAt desc' } = params

  const businessUnit = useBusinessUnit()

  return useQuery<OrderPagedQueryResponse, ErrorObject, OrderPagedQueryResponse>(
    ['orders-b2b', search, from, to, shippingAddress, page, sort, limit, businessUnit.id, businessUnit.storeKey],
    () => fetchBuOrders({ ...params, businessUnitKey: businessUnit.key, storeKeys: businessUnit.storeKey }),
    {
      enabled: !!businessUnit.key && !!businessUnit.storeKey,
      ...config,
    },
  )
}
