import { Order, OrderFromCartDraft, OrderPagedQueryResponse } from '@commercetools/platform-sdk'
import { QueryFunctionContext } from 'react-query'

import {
  setOrderCustomerId,
  setOrderPurchaseDocumentId,
  setOrderPurchaseDocumentName,
} from 'commercetools/api/actions/order'
import {
  UpdateActionValue,
  OrderQueryParams,
  OrderQueryParamsInStore,
  OrderQueryParamsAsAssociate,
} from 'commercetools/types'
import { buildURLSearchParams } from 'commercetools/utils/buildURLSearchParams'

import client from '../client'

export const createOrder = (cartId: string | undefined, cartVersion: number, storeKey: string): Promise<Order> =>
  client.post<Order, OrderFromCartDraft>(storeKey ? `/in-store/key=${storeKey}/me/orders` : '/orders', {
    id: cartId,
    version: cartVersion,
    cart: { id: cartId, typeId: 'cart' },
  })

export const updateOrder = (orderId: string | undefined, orderVersion: number, customerId: string): Promise<Order> =>
  client.post<Order, UpdateActionValue>(`/orders/${orderId}`, {
    version: orderVersion,
    actions: [setOrderCustomerId(customerId)],
  })

export const updateOrderPurchaseDocument = (
  id: string | undefined,
  version: number,
  fileId: string,
  fileName: string,
): Promise<Order> =>
  client.post<Order, UpdateActionValue>(`/orders/${id}`, {
    version: version,
    actions: [setOrderPurchaseDocumentId(fileId), setOrderPurchaseDocumentName(fileName)],
  })

type ArgumentsFetchOrderById = QueryFunctionContext<[queryFnName: 'fetchOrderById', orderId: string]>
export const fetchOrderById = ({ signal, queryKey: [, orderId] }: ArgumentsFetchOrderById): Promise<Order> =>
  client.get<Order>(`/orders/${orderId}`, void 0, { signal })

type ArgumentsFetchOrderByIdAsAssociate = QueryFunctionContext<
  [queryFnName: 'fetchOrderByIdAsAssociate', orderId: string, businessUnitKey: string, associateId: string]
>
export const fetchOrderByIdAsAssociate = ({
  signal,
  queryKey: [, orderId, businessUnitKey, associateId],
}: ArgumentsFetchOrderByIdAsAssociate): Promise<Order> =>
  client.get<Order>(`/as-associate/${associateId}/in-business-unit/key=${businessUnitKey}/orders/${orderId}`, void 0, {
    signal,
  })

const expand = ['createdBy.customer']

export const fetchBuOrders = (params: OrderQueryParamsInStore, token?: string): Promise<OrderPagedQueryResponse> => {
  const { from, to, shippingAddress: _, businessUnitKey, limit, page, storeKeys, ...rest } = params
  const storeKey = Array.isArray(storeKeys) ? storeKeys[0] : storeKeys

  if (!businessUnitKey || !storeKey) {
    throw new Error('customer id or company key or store key not set when fetching cart')
  }

  const where = [
    from && `createdAt > "${from}"`,
    to && `createdAt < "${to}"`,
    businessUnitKey && Array.isArray(businessUnitKey)
      ? `businessUnit(key in ${businessUnitKey.map((id) => `"${id}"`).join(', ')})`
      : `businessUnit(key="${businessUnitKey}")`,
  ]
    .filter(Boolean)
    .join(' and ')

  const searchParams = {
    offset: limit * (page - 1),
    limit,
    ...rest,
  }
  return client.get<OrderPagedQueryResponse>(
    `/in-store/key=${storeKey}/me/orders?${buildURLSearchParams({ ...searchParams, where, expand })}`,
    token,
  )
}

export const fetchBuOrdersAsAssociate = (
  params: OrderQueryParamsAsAssociate,
  token?: string,
): Promise<OrderPagedQueryResponse> => {
  const { from, to, shippingAddress: _, businessUnitKey, limit, page, associateId, ...rest } = params

  if (!businessUnitKey || !associateId) {
    throw new Error('Associate id or company key not set when fetching orders')
  }
  const where = [
    from && `createdAt > "${from}"`,
    to && `createdAt < "${to}"`,
    businessUnitKey && Array.isArray(businessUnitKey)
      ? `businessUnit(key in ${businessUnitKey.map((id) => `"${id}"`).join(', ')})`
      : `businessUnit(key="${businessUnitKey}")`,
  ]
    .filter(Boolean)
    .join(' and ')

  const searchParams = {
    offset: limit * (page - 1),
    limit,
    ...rest,
  }
  return client.get<OrderPagedQueryResponse>(
    `/as-associate/${associateId}/in-business-unit/key=${businessUnitKey}/orders?${buildURLSearchParams({
      ...searchParams,
      where,
      expand,
    })}`,
    token,
  )
}

export const fetchOrders = (params: OrderQueryParams, token?: string): Promise<OrderPagedQueryResponse> => {
  const { from, to, shippingAddress: _, customerId, limit, page, ...rest } = params

  const where = [
    from && `createdAt > "${from}"`,
    to && `createdAt < "${to}"`,
    customerId && Array.isArray(customerId)
      ? `customerId in (${customerId.map((id) => `"${id}"`).join(', ')})`
      : `customerId = "${customerId}"`,
  ]
    .filter(Boolean)
    .join(' and ')

  const searchParams = {
    offset: limit * (page - 1),
    limit,
    ...rest,
  }
  return client.get<OrderPagedQueryResponse>(
    `/orders?${buildURLSearchParams({ ...searchParams, where, expand })}`,
    token,
  )
}
