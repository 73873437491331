import { Box, Link, Table, TableProps, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { OrderPagedQueryResponse } from '@commercetools/platform-sdk'
import NextLink from 'next/link'
import router from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useMemo, useState } from 'react'

import { getOrderStatusText } from 'commercetools/utils/order/getOrderStatusText'
import { ErrorAlert } from 'components/elements/ErrorAlert'
import { SearchInputGroup } from 'components/elements/SearchInputGroup'
import { Price } from 'components/modules/Price'
import { RoutePaths } from 'config/routes'
import { arrayHasValue } from 'utils/arrayHasValue'
import { dateToFormattedString } from 'utils/dateToFormattedString'

type Props = TableProps & {
  orders: OrderPagedQueryResponse | undefined
  showSearchInput?: boolean
  /**
   * @default 'customer'
   */
  type?: 'customer' | 'business-unit'
}

export function OrdersTable({ orders, showSearchInput = true, type = 'customer', ...props }: Props) {
  const { t } = useTranslation('order')
  const [filterText, setFilterText] = useState('')

  const filterInputChange = (text: string) => {
    if (text.length > 2) {
      setFilterText(text.toLowerCase())
    } else {
      setFilterText('')
    }
  }

  const filter = useCallback(
    (prop: string | number) => prop?.toString().toLowerCase().includes(filterText),
    [filterText],
  )

  const filteredOrders = useMemo(
    () =>
      orders?.results.filter((order) => {
        if (!filterText) {
          return orders?.results
        }

        return (
          (order.orderNumber ? filter(order.orderNumber) : filter(t('under-processing'))) ||
          filter(order.custom?.fields?.purchaseOrderReferenceNumber) ||
          filter(dateToFormattedString(order?.createdAt)) ||
          filter(getOrderStatusText(order?.orderState ?? '', t)) ||
          filter(order?.custom?.fields?.customerUserFriendlyId?.split(',')[0]) ||
          filter(order.totalPrice.centAmount) ||
          filter(order.lineItems.length)
        )
      }),
    [filter, filterText, orders?.results, t],
  )

  const orderDetailsPath = type === 'business-unit' ? RoutePaths.myOrderHistoryB2B : RoutePaths.myOrderHistory

  return (
    <>
      {showSearchInput && <SearchInputGroup onChange={filterInputChange} />}

      {type === 'business-unit' && (
        <Link as={NextLink} href={RoutePaths.myOrderHistory} alignSelf="self-start">
          {t('click-here-to-see-legacy-orders')}
        </Link>
      )}

      <Box
        width="full"
        mt={{ base: '2', md: '0' }}
        position={{ base: 'relative', md: 'static' }}
        overflow={{ base: 'auto', md: 'inherit' }}
        whiteSpace={{ base: 'nowrap', md: 'normal' }}
      >
        {arrayHasValue(orders?.results) ? (
          <>
            <Table variant="responsive" mt="2" {...props}>
              <Thead>
                <Tr>
                  <Th width={{ base: '100px', md: '10%' }}>
                    <Text>{t('order-date')}</Text>
                  </Th>
                  <Th width="20%">
                    <Text>{t('purchase-order-no')}</Text>
                  </Th>
                  <Th width="20%">
                    <Text>{t('order-number')}</Text>
                  </Th>
                  <Th width="10%">
                    <Text>{t('order-status')}</Text>
                  </Th>
                  <Th width="10%">
                    <Text>{t('ordered-by')}</Text>
                  </Th>
                  <Th width="4%">
                    <Text>{t('common:quantity-abbreviation')}</Text>
                  </Th>
                  <Th width="7%">
                    <Text>{t('order-total')}</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredOrders?.map((order, index) => (
                  <Tr key={index} cursor="pointer" onClick={() => router.push(`${orderDetailsPath}/${order.id}`)}>
                    <Td>{dateToFormattedString(order?.createdAt)}</Td>
                    <Td>{order?.custom?.fields?.purchaseOrderReferenceNumber ?? '-'}</Td>
                    <Td>{order?.orderNumber ? order?.orderNumber : t('under-processing')}</Td>
                    <Td>{getOrderStatusText(order?.orderState ?? '', t)}</Td>
                    <Td>{order?.custom?.fields?.customerUserFriendlyId?.split(',')[0] || ''}</Td>
                    <Td>{order.lineItems.length}</Td>
                    <Td>
                      <Price price={order.totalPrice} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        ) : (
          <ErrorAlert>{t('common:couldnt-find-any-items')}</ErrorAlert>
        )}
      </Box>
    </>
  )
}
